<template>
  <v-container
    id="course-detail"
    fluid
    tag="section"
  >

    <dialog-delete
      ref="dialogDeleteRef"
      @courseDeleted="goBackCatalog"
    />

    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          id="card-course-detail"
          color="primary"
          :title="course.name"
        >

          <v-card-text class="text-center">
            <v-simple-table
              class="table-course-detail"
            >
              <tbody>
                <tr>
                  <th>
                    <v-icon>mdi-barcode-scan</v-icon>
                  </th>
                  <th>
                    Référence
                  </th>
                  <td>
                    {{ course.reference }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <v-icon>mdi-eye-check-outline</v-icon>
                  </th>
                  <th>
                    Visibilité
                  </th>
                  <td>
                    {{ course.visible ? 'Public' : 'Privé' }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <v-icon>mdi-timer-sand</v-icon>
                  </th>
                  <th>
                    Durée
                  </th>
                  <td>
                    {{ course.nbDays }} jour{{ (course.nbDays > 1 ? 's' : '') }} ({{ course.nbHours }} heures)
                  </td>
                </tr>
                <tr
                  v-if="course.price"
                >
                  <th>
                    <v-icon>mdi-currency-eur</v-icon>
                  </th>
                  <th>
                    Prix INTER
                  </th>
                  <td>
                    {{ course.price }} € HT
                  </td>
                </tr>
                <tr
                  v-if="course.priceIntra"
                >
                  <th>
                    <v-icon>mdi-currency-eur</v-icon>
                  </th>
                  <th>
                    Prix INTRA
                  </th>
                  <td>
                    {{ course.priceIntra }} € HT
                  </td>
                </tr>
                <tr
                  v-if="course.description"
                >
                  <th>
                    <v-icon>mdi-text-long</v-icon>
                  </th>
                  <th>
                    Description
                  </th>
                  <td>
                    {{ displayDescription() }}
                    <span
                      v-if="course.description.length > 140 && !descriptionSeeMore"
                      class="see-more-btn"
                      @click="descriptionSeeMore = true"
                    >
                      Voir plus
                    </span>
                    <span
                      v-if="course.description.length > 140 && descriptionSeeMore"
                      class="see-more-btn"
                      @click="descriptionSeeMore = false"
                    >
                      Voir moins
                    </span>
                  </td>
                </tr>
                <tr
                  v-if="course.commentaires"
                >
                  <th>
                    <v-icon>mdi-comment-processing-outline</v-icon>
                  </th>
                  <th>
                    Commentaires
                  </th>
                  <td>
                    {{ displayCommentaires() }}
                    <span
                      v-if="course.commentaires.length > 140 && !commentairesSeeMore"
                      class="see-more-btn"
                      @click="commentairesSeeMore = true"
                    >
                      Voir plus
                    </span>
                    <span
                      v-if="course.commentaires.length > 140 && commentairesSeeMore"
                      class="see-more-btn"
                      @click="commentairesSeeMore = false"
                    >
                      Voir moins
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </base-material-card>

        <base-material-card
          color="primary"
          title="Public visé"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-chip
              class="ma-2 chips-audience"
              v-for="aud in course.audience"
              :key="aud"
            >
              {{ aud }}
            </v-chip>
          </v-card-text>
        </base-material-card>

        <base-material-card
          color="primary"
          title="Informations pratiques"
          class="px-5 py-3"
        >
          <div class="my-3">
            <h4>Prérequis</h4>
            <ul>
              <li
                v-for="prerequis in course.prerequies"
                :key="prerequis"
                class="pa-1"
              >
                {{ prerequis }}
              </li>
            </ul>
          </div>
          <div class="my-3">
            <h4>Prérequis techniques</h4>
            <p style="white-space: pre-line">{{ course.prerequiesTech }}</p>
          </div>
          <div class="my-3">
            <h4>Objectifs</h4>
            <ul>
              <li
                v-for="objective in course.objectives"
                :key="objective"
                class="pa-1"
              >
                {{ objective }}
              </li>
            </ul>
          </div>
          <div class="my-3">
            <h4>Moyens pédagogiques</h4>
            <p style="white-space: pre-line">{{ course.moyensPedagogiques }}</p>
          </div>
          <div class="my-3">
            <h4>Modalités d'évaluation</h4>
            <p style="white-space: pre-line">{{ course.modalitesEvaluation }}</p>
          </div>
          <div class="my-3">
            <h4>Délais d'accès</h4>
            <p style="white-space: pre-line">{{ course.delaisAcces }}</p>
          </div>
          <div class="my-3">
            <h4>Accessibilité</h4>
            <p style="white-space: pre-line">{{ course.accessibilite }}</p>
          </div>
        </base-material-card>

        <base-material-card
          color="primary"
          title="Plan de cours"
          class="px-5 py-3"
        >
          <v-card-text
            id="plan-de-cours"
          >
            <div
              v-html="course.plan"
            >
            </div>
          </v-card-text>
        </base-material-card>

      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          color="primary"
          title="Actions"
        >
          <v-card-text class="text-center">
            <v-btn
              color="blue"
              class="ma-2"
              :to="{ name: 'ModifierCours', params: { id: course.id } }"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
            <v-btn
              color="#2a63b9"
              class="ma-2"
              @click="downloadDocx()"
              :disabled="downloadDocxDisabled"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger au format DOCX
            </v-btn>
            <v-btn
              color="#c74f46"
              class="ma-2"
              @click="downloadPdf()"
              :disabled="downloadPdfDisabled"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger au format PDF
            </v-btn>
            <v-btn
              color="gray"
              light
              class="ma-2"
              @click="$router.push({ name: 'CreerCours', params: { course: course } });"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>
              Dupliquer
            </v-btn>
            <v-btn
              color="red darken-1"
              class="ma-2"
              @click="$refs.dialogDeleteRef.deleteItem(course)"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Supprimer
            </v-btn>
          </v-card-text>
        </base-material-card>
        <base-material-card
          color="primary"
          title="Prochaines sessions de formation"
          class="px-5 py-3"
        >
          <v-data-table
            class="table-formations"
            :headers="headers"
            :items="formationsOfCourse"
            :search="search"
            :sort-by="'dateDebut'"
            :sort-desc="false"
            @click:row="rowClick"
          >
            <template v-slot:[`item.intitule`]="{ item }">
              {{ item.intitule.slice(10) }}
              <v-tooltip
                bottom
                v-if="item.etat === 'CONFIRME'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Confirmée</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.formateurs`]="{ item }">
              <span v-if="item.formateurs.length > 0">
                {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
              </span>
              <span v-else>
                <i>Aucun</i>
              </span>
            </template>
            <template v-slot:[`item.adresse`]="{ item }">
              {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span
                class="badge-chips-small"
              >
                {{ item.type }}
              </span>
            </template>
          </v-data-table>
          <v-row>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                class="mr-0"
                type="submit"
                @click="$router.push({ name: 'CreerFormation', params: { course: course } });"
              >
                Créer une session à partir de ce cours
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    name: 'CoursDetail',

    data () {
      return {
        search: '',
        course: {},
        formationsOfCourse: [],
        headers: [
          {
            sortable: false,
            text: 'Lieu',
            value: 'adresse',
          },
          {
            sortable: false,
            text: 'Date',
            value: 'dateDebutFormatted',
          },
          {
            sortable: false,
            text: 'Type',
            value: 'type',
          },
          {
            sortable: true,
            text: 'Formateur(s)',
            value: 'formateurs',
          },
        ],
        commentairesSeeMore: false,
        descriptionSeeMore: false,
        downloadDocxDisabled: false,
        downloadPdfDisabled: false,
      }
    },

    components: {
      DialogDelete: () => import('../../component/DialogDelete'),
    },

    created () {
      this.axios.get('/cours/' + this.$route.params.id + '/prochainesSessionsAll')
        .then((res) => {
          this.formationsOfCourse = [...res.data];

          this.axios.get('/cours/' + this.$route.params.id)
            .then((res) => {
              this.course = res.data;

              this.formationsOfCourse.forEach(formation => {
                formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
                formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
                if (formation.distanciel) {
                  formation.adresse = { ville: 'Visio' };
                }
              });
            })
            .catch(e => {
              this.setSnack({ text: 'Erreur lors de la récupération du cours', type: 'warn' });
            });
        });
    },

    methods: {
      goBackCatalog() {
        this.$router.push({ name: 'Catalogue' });
      },

      displayCommentaires() {
        if (this.course.commentaires.length <= 140 || this.commentairesSeeMore) {
          return this.course.commentaires;
        } else {
          return this.course.commentaires.substring(0, 140) + '...';
        }
      },

      displayDescription() {
        if (this.course.description.length <= 140 || this.descriptionSeeMore) {
          return this.course.description;
        } else {
          return this.course.description.substring(0, 140) + '...';
        }
      },

      rowClick(item, row) {
        this.$router.push({ name: 'FormationDetail', params: { id: item.id } });
      },

      downloadDocx() {
        this.downloadDocxDisabled = true;
        this.axios.get('/documents/programme/' + this.course.id + '/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/docx' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Programme_' + this.course.reference + '.docx');
            document.body.appendChild(link);
            link.click();
            this.downloadDocxDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du programme de cours', type: 'warn' });
            this.downloadDocxDisabled = false;
          });
      },

      downloadPdf() {
        this.downloadPdfDisabled = true;
        this.axios.get('/documents/programme/' + this.course.id + '/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Programme_' + this.course.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadPdfDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du programme de cours', type: 'warn' });
            this.downloadPdfDisabled = false;
          });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  .see-more-btn {
    cursor: pointer;
    color: #00000080;
    text-decoration: underline dotted #00000080;
  }

  .table-course-detail th, .table-course-detail td {
    font-size: 0.9rem !important;
    text-align: left;
  }

  .table-course-detail th:first-child {
    width: 1%;
  }

  .table-course-detail th:nth-child(2) {
    width: 33%;
  }

  .course-detail-list .v-list-item__title {
    font-weight: 400 !important;
    font-size: 16px !important;
  }

  .chips-audience .v-chip__content {
    font-size: 14px;
  }

  #plan-de-cours {
    font-size: 0.9em;
  }

</style>
